import styled from 'styled-components';
import { useChatStore } from 'services/ChatService';
import { useWindowStore } from 'services/WindowService';
import ChatBar from 'uiLivi/components/Chat/ChatBar';
import ChatContainer from 'uiLivi/components/Chat/ChatContainer';
import { CONTENT_TYPE } from 'services/DistrictService/contentTypes';
import { useContentStore } from 'services/ContentService';
import { zIndex } from 'uiLivi/styles';
import ChatTracking from 'services/TrackingService/ChatTracking';
import { useCameraStore } from 'services/CameraService';

const StyledChat = styled.div`
  position: absolute;
  z-index: ${zIndex.chat};
  color: white;
  top: 0;
  right: 0;
  transform: translateX(${props => (props.visible ? 0 : props.chatWidth) - (props.isShiftedByVideoChat ? 300 : 0)}px);
  transition: transform ${props => (props.visible ? 0.75 : 0)}s ease;
  transition-delay: ${props => (props.visible ? 0.15 : 0)}s;
`;

const Chat = () => {
  const isActive = useChatStore(state => state.isActive);
  const isContentChat = useChatStore(state => state.chatType === 'content');
  const windowWidth = useWindowStore(state => state.width);
  const isMobile = useWindowStore(state => state.isMobile);
  const chatWidth = isMobile ? windowWidth : 320;
  const activeContent = useContentStore(state => state.activeContent);
  const isZoom = activeContent && activeContent.type === CONTENT_TYPE.ZOOM;
  const isFree = useCameraStore(state => state.mode === 'free');
  const isShiftedByVideoChat = useChatStore(state => state.isShiftedByVideoChat);
  const chatEnabled = !isZoom && isFree;

  return (
    <StyledChat visible={chatEnabled} chatWidth={chatWidth} isShiftedByVideoChat={isShiftedByVideoChat}>
      <ChatTracking />
      <ChatBar visible={chatEnabled} chatWidth={chatWidth} isActive={isActive} isContentChat={isContentChat} />
      <ChatContainer chatWidth={chatWidth} />
    </StyledChat>
  );
};

export default Chat;
