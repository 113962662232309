import create from 'utilities/zustand/create';
import userStore from 'storage/user';
import { LANDING_URL } from 'index';
import { useContentStore } from 'services/ContentService';
import { useActivityStore } from 'services/ActivityService';
import { useTrackingStore } from 'services/TrackingService';
import { usePeopleStore } from 'services/PeopleService';
import { useSocketStore } from 'services/SocketService';
import { useDistrictStore } from 'services/DistrictService';
import { useEventStore } from 'services/EventService';
import { useExpertStore } from 'services/ExpertService';
import { useCronService } from 'services/CronService';
import { useChatStore } from 'services/ChatService';
import { useTalkStore } from 'services/TalkService';
import { configureSession } from 'utilities/userSession';
import WebGL from 'three/examples/jsm/capabilities/WebGL';
import { useTheaterStore } from '../TheaterService';
import { useLiveStreamStore } from '../LiveStreamService';
import { useProfilingStore } from '../ProfilingService';
import { useNotificationStore } from '../NotificationService';
import Toast from 'common/components/Toast';
import { usePlayerStore } from 'services/PlayerService';
import { useTranslationStore } from 'services/TranslationService';
import { useGlobalHubStore } from 'services/GlobalHubService';
import { Buffer } from 'buffer';
import { useAvatarStore } from '../AvatarService';

let ms = 0;
let lastTime = Date.now();

function countFps() {
  const now = Date.now();
  ms += (now - lastTime - ms) * 0.1;
  lastTime = now;
  requestAnimationFrame(countFps);
}

countFps();

const webgl2 = WebGL.isWebGL2Available();
let socket = null;
export const useUserStore = create((set, get) => ({
  user: null,
  selectedUserId: null,
  users: [],
  loginState: 'PENDING',
  message: null,
  hasFocus: true,
  isIdle: false,
  player: {
    user: {
      appearance: null,
    },
  },

  init: async skipLogin => {
    const token = userStore.getToken();
    if (token && !skipLogin) {
      await get().login({ token });
    } else {
      set({ loginState: 'LOGGED_OUT' });
    }

    configureSession({
      update: properties => {
        const { focus: hasFocus, idle: isIdle } = properties;
        set({ hasFocus, isIdle });
      },
    });

    configureSession({
      update: properties => {
        const { focus: hasFocus, idle: isIdle } = properties;
        set({ hasFocus, isIdle });
      },
    });
  },

  register: credentials => {
    credentials.register = true;
    get().login(credentials);
  },

  resetMessage: () => {
    set({ message: null });
  },
  setMessage: message => {
    set({ message });
  },

  login: credentials => {
    set({ loginState: 'PENDING' });
    socket = useSocketStore.getState().connect();
    const translate = useTranslationStore.getState().translate;

    socket.on('disconnect', () => {
      // eslint-disable-next-line no-console
      console.log('UserService socket.disconnect');
      get().resetStates();
      set({ loginState: 'LOGGED_OUT' });
    });

    socket.on('user/sameUserLoggedIn', () => {
      get().disconnect({
        copy:
          translate('error.autologout') ||
          'You have been automatically logged out because the same user is logged in on another browser or tab.',
      });
    });

    socket.on('error/wrongProtocol', () => {
      get().disconnect({
        copy:
          'Error:wrongProtocol - your client has an old network protocol. Please update your branch or reload application.',
      });
    });

    socket.on('reconnect', async () => {
      // eslint-disable-next-line no-console
      console.log('UserService socket.reconnect');
      const token = userStore.getToken();
      if (token) {
        await get().login({ token });
      } else {
        set({ loginState: 'LOGGED_OUT' });
      }
    });

    socket.on('user/ping', time => {
      const { isIdle, hasFocus } = get();
      socket.emit('user/pong', {
        time,
        ms: Math.round(ms) % 65535,
        isIdle,
        hasFocus,
        width: Math.round(window.innerWidth) % 65535,
        height: Math.round(window.innerHeight) % 65535,
        devicePixelRatio: window.devicePixelRatio,
        webgl2: webgl2,
        post: false,
      });
    });

    return new Promise(resolve => {
      socket.once('reconnect_failed', () => {
        set({ loginState: 'LOGGED_OUT', message: { copy: 'Could not connect' } });
        resolve();
      });

      const command = credentials.register ? 'register' : 'login';
      const { event } = useEventStore.getState();
      socket.emit('user/' + command, { ...credentials, eventId: event.id }, async result => {
        const { user, player, token } = result;
        if (user != null) {
          useProfilingStore.getState().mark('Init User APIs');
          userStore.setToken(token);
          player.user = { ...player.user, isSelf: true };
          set({ user, player });
          await get().initStates(user);
          useActivityStore.getState().addActivity({ activity: 'landing', command, url: LANDING_URL });
          set({ loginState: 'LOGGED_IN' });
          useProfilingStore.getState().mark('Init User APIs Complete');
          resolve();
        } else {
          let { message } = result;
          // TODO this can't be right, make sure plaintext messages aren't used as error type
          const translate = useTranslationStore.getState().translate;
          if (message === 'Identifier or password invalid.') {
            message = translate('toast.loginincorrect') || 'The email address or password is incorrect. Please retry.';
          }
          if (message === 'Email is already taken.') {
            message =
              translate('toast.mailtaken') || 'That email address is already taken. Please choose a different one.';
          }
          if (message === 'INVALID_EVENT_CODE' || message === 'INVALID_EXPERT_CODE') {
            message = translate('toast.invalidcode') || 'The entered code is wrong.';
          }
          get().disconnect({ copy: message ? message.toString() : null });
        }
      });
    });
  },

  initStates: async user => {
    // eslint-disable-next-line no-console
    useAvatarStore.getState().initConfigurationFromAppearance(user.appearance);
    useTrackingStore.getState().onUser(user);
    useActivityStore.getState().init(socket);
    useExpertStore.getState().init(socket);
    usePeopleStore.getState().init(socket);
    await useDistrictStore.getState().init(socket);
    useCronService.getState().init(socket);
    await useTalkStore.getState().init(socket);
    useChatStore.getState().init(socket);
    useTheaterStore.getState().init();
    useLiveStreamStore.getState().init();
    usePlayerStore.getState().init(socket);
    useGlobalHubStore.getState().init(socket);
  },

  resetStates: () => {
    // eslint-disable-next-line no-console
    console.log('resetStates');
    useContentStore.getState().logout();
    useChatStore.getState().reset();
    usePeopleStore.getState().logout();
    useDistrictStore.getState().logout();
    useGlobalHubStore.getState().exit();
  },

  logout: () => {
    userStore.setToken(null);
    get().disconnect();
    set({
      user: null,
      player: {
        user: {
          appearance: null,
        },
      },
    });
  },

  disconnect: message => {
    // eslint-disable-next-line no-console
    console.log('UserService.disconnect', message);
    get().resetStates();
    set({ name: '', id: null, loginState: 'LOGGED_OUT', message });
    useSocketStore.getState().disconnect();
  },

  previewAppearance: appearance => {
    const { player } = get();
    player.user.appearance = appearance;
    set({ player });
  },

  changeAppearance: appearance => {
    get().previewAppearance(appearance);
    socket.emit('user/appearanceUpdate', appearance);
  },

  updateProfile: data => {
    return new Promise((resolve, reject) => {
      socket.emit('user/updateProfile', data, result => {
        const { statusCode } = result;
        if (statusCode === 200) {
          const { user } = result;
          let { users } = get();
          users = users.filter(u => u.id !== user.id);
          users.push(user);
          set({ user: { ...user }, users: [...users] });
          resolve();
        } else {
          reject(result);
        }
      });
    });
  },

  deleteProfilePicture: () => {
    get().listenToUpdateProfilePictureCompleteOnce();
    socket.emit('user/deleteProfilePicture');
  },

  updateProfilePicture: profilePictureBuffer => {
    const translate = useTranslationStore.getState().translate;
    const MAX_UPLOAD_SIZE = 1024 * 1024 * 10;
    if (profilePictureBuffer.byteLength > MAX_UPLOAD_SIZE) {
      const toast = (
        <Toast
          headline={translate('profile.picture.upload.error.headline') || 'Cannot not use this picture.'}
          text={translate('profile.picture.upload.error.size') || 'The file must be smaller than 10 MB.'}
          level={'error'}
          position={'bottom'}
        />
      );
      useNotificationStore.getState().addNotification(toast);
      return;
    }
    get().listenToUpdateProfilePictureCompleteOnce();
    socket.emit('user/updateProfilePicture', { buffer: new Buffer(profilePictureBuffer) });
  },

  listenToUpdateProfilePictureCompleteOnce: () => {
    const translate = useTranslationStore.getState().translate;
    socket.once('user/updateProfilePictureComplete', result => {
      const { statusCode } = result;
      if (statusCode === 200) {
        const { update } = result;
        const { user, users } = get();
        user.profilePicture = update;
        set({ user: { ...user } });
        const newUsers = users.map(u => {
          if (u.id === user.id) {
            return { ...user };
          } else {
            return user;
          }
        });
        set({ users: newUsers });
      } else {
        const toast = (
          <Toast
            headline={translate('profile.picture.upload.error.headline') || 'Cannot not use this picture.'}
            text={
              translate('profile.picture.upload.error.format') ||
              'The image format is not supported. Please use JPEG, PNG, WebP, TIFF, GIF or SVG.'
            }
            level={'error'}
            position={'bottom'}
          />
        );
        useNotificationStore.getState().addNotification(toast);
      }
    });
  },

  requestUser: (requestedUserId, updateIfCached = false) => {
    if (requestedUserId == null) {
      // eslint-disable-next-line no-console
      return console.warn('requested an invalid userId in UserService, please do not attempt that.');
    }
    const { users: cachedUsers, user: ownUser } = get();
    if (!updateIfCached && cachedUsers.find(u => u.id === requestedUserId)) return;

    if (requestedUserId === ownUser.id && !cachedUsers.find(u => u.id === ownUser.id)) {
      return set({ users: [...cachedUsers, ownUser] });
    }

    socket.emit('user/info', requestedUserId, result => {
      const newCachedUsers = get().users;
      const requestedUser = newCachedUsers.find(u => u.id === requestedUserId);
      if (requestedUser) {
        // update user
        const userIndex = newCachedUsers.findIndex(u => u.id === requestedUserId);
        newCachedUsers[userIndex] = { ...newCachedUsers[userIndex], ...result };
        set({ users: [...newCachedUsers] });
      } else {
        // push new user into array
        set({ users: [...newCachedUsers, result] });
      }
    });
  },

  selectUserById: userId => {
    if (userId != null) {
      get().requestUser(userId, true);
    }
    set({ selectedUserId: userId });
  },
}));

if (module.hot) module.hot.decline();
