import create from 'utilities/zustand/create';
import { Howl } from 'howler';
import { getEventSoundConfig } from './config';
import { useDebugStore } from 'storage/debug';

export const useHowlerStore = create((set, get) => ({
  isHowlerEnabled: false,

  effectSoundsConfig: [],

  ambient: [],
  effectSounds: [],

  init: () => {
    const eventSoundConfig = getEventSoundConfig();
    const effectSounds = eventSoundConfig.effectSounds;
    set({ effectSoundsConfig: effectSounds });
    get().initEffectSounds(effectSounds);
  },

  // init and update all sounds
  startDistrictHowls: districtName => {
    const eventSoundConfig = getEventSoundConfig();
    const districtSoundConfig = eventSoundConfig.districts.find(c => c.name === districtName);
    if (!districtSoundConfig) {
      // eslint-disable-next-line no-console
      console.warn('no soundConfig for ', districtName);
      return;
    }
    const ambient = districtSoundConfig.ambient;

    get().initAmbient(ambient);
    get().updateAmbient();
  },

  // update all sound states
  setEnabled: enabled => {
    set({ isHowlerEnabled: enabled });
    get().updateAmbient();
  },

  //clear all the sounds
  stopDistrictHowls: () => {
    get().clearAmbient();
  },

  //create and set all howler instances
  initAmbient: ambientConfig => {
    if (ambientConfig) {
      const ambientHowls = ambientConfig.map(sound => {
        const howl = new Howl({
          src: [sound.src.webm, sound.src.mp3],
          loop: sound.loop,
        });
        return howl;
      });

      set({
        ambient: [...ambientHowls],
      });
    }
  },

  initEffectSounds: effectSoundsConfig => {
    if (effectSoundsConfig) {
      const effectSoundsHowls = effectSoundsConfig.map(sound => {
        return {
          name: sound.name,
          howl: new Howl({
            src: [sound.src.webm, sound.src.mp3],
            loop: sound.loop,
          }),
        };
      });

      set({
        effectSounds: [...effectSoundsHowls],
      });
    }
  },

  // deal with the new state
  updateAmbient: () => {
    const ambient = get().ambient;

    ambient.forEach(howl => {
      if (get().isHowlerEnabled) {
        if (useDebugStore.getState().getAmbientMusicEnabled()) {
          if (!howl.playing()) {
            howl.play();
            howl.fade(0, 1, 2500);
          }
        }
      } else {
        howl.stop();
      }
    });
  },

  // stop and unset all howler instances
  clearAmbient: () => {
    const { ambient } = get();
    if (ambient !== []) {
      ambient.forEach(howl => {
        howl.unload();
      });

      set({ ambient: [] });
    }
  },

  getEffectSound: soundName => {
    const effectSound = get().effectSounds.find(sound => sound.name === soundName);
    return effectSound.howl;
  },

  createEffectSound: soundName => {
    const effectSounds = get().effectSoundsConfig.find(sound => sound.name === soundName);
    if (effectSounds) {
      return new Howl({
        src: [effectSounds.src.webm, effectSounds.src.mp3],
        loop: effectSounds.loop,
      });
    }
  },

  playEffectSounds: reactionId => {
    const emoji = get().getEffectSound('emoji');
    const isEmojiAllowedToPLay = reactionId !== 0 && reactionId !== 10 && get().isHowlerEnabled;

    isEmojiAllowedToPLay ? emoji.play() : emoji.stop();
  },

  playClickUiSound: () => {
    const clickSound = get().getEffectSound('clickUi');

    get().isHowlerEnabled && clickSound.play() && !clickSound.playing();
  },

  fadeOutSound: () => {
    const ambient = get().ambient;
    const effectSounds = get().effectSounds;

    const allSounds = [...ambient, ...effectSounds.map(sound => sound.howl)];
    return allSounds.forEach(sound => sound.fade(1, 0, 1500));
  },

  fadeInSound: () => {
    const ambient = get().ambient;
    const effectSounds = get().effectSounds;

    const allSounds = [...ambient, ...effectSounds.map(sound => sound.howl)];
    return allSounds.forEach(sound => sound.fade(0, 1, 1500));
  },
}));
