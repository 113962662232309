export const getEventSoundConfig = () => {
  return {
    effectSounds: [
      {
        name: 'emoji',
        src: {
          mp3: 'https://dxp24t9jou16u.cloudfront.net/shared/howler/mp3/emojis.mp3',
          webm: 'https://dxp24t9jou16u.cloudfront.net/shared/howler/webm/emojis.webm',
        },
        loop: false,
      },
      {
        name: 'teleport',
        src: {
          mp3: 'https://dxp24t9jou16u.cloudfront.net/shared/howler/mp3/teleport.mp3',
          webm: 'https://dxp24t9jou16u.cloudfront.net/shared/howler/webm/teleport.webm',
        },
        loop: false,
      },
      {
        name: 'walkForward',
        src: {
          mp3: 'https://dxp24t9jou16u.cloudfront.net/shared/howler/mp3/footsteps1.mp3',
          webm: 'https://dxp24t9jou16u.cloudfront.net/shared/howler/webm/footsteps1.webm',
        },
        loop: true,
      },
      {
        name: 'walkBackward',
        src: {
          mp3: 'https://dxp24t9jou16u.cloudfront.net/shared/howler/mp3/footsteps-bkwd.mp3',
          webm: 'https://dxp24t9jou16u.cloudfront.net/shared/howler/webm/footsteps-bkwd.webm',
        },
        loop: true,
      },
      {
        name: 'clickUi',
        src: {
          mp3: 'https://dxp24t9jou16u.cloudfront.net/shared/howler/mp3/select.mp3',
          webm: 'https://dxp24t9jou16u.cloudfront.net/shared/howler/webm/select.webm',
        },
        loop: false,
      },
    ],
    districts: [
      {
        name: 'auditorium',
        ambient: [
          {
            src: {
              mp3: 'https://dxp24t9jou16u.cloudfront.net/shared/howler/mp3/amb-auditorium.mp3',
              webm: 'https://dxp24t9jou16u.cloudfront.net/shared/howler/webm/amb-auditorium.webm',
            },
            loop: true,
          },
        ],
      },
      {
        name: 'testAuditorium',
        ambient: [
          {
            src: {
              mp3: 'https://dxp24t9jou16u.cloudfront.net/shared/howler/mp3/amb-auditorium.mp3',
              webm: 'https://dxp24t9jou16u.cloudfront.net/shared/howler/webm/amb-auditorium.webm',
            },
            loop: true,
          },
        ],
      },
      {
        name: 'lobby',
        ambient: [
          {
            src: {
              mp3: 'https://dxp24t9jou16u.cloudfront.net/shared/howler/mp3/music.mp3',
              webm: 'https://dxp24t9jou16u.cloudfront.net/shared/howler/webm/music.webm',
            },
            loop: true,
          },
          {
            src: {
              mp3: 'https://dxp24t9jou16u.cloudfront.net/shared/howler/mp3/amb-water.mp3',
              webm: 'https://dxp24t9jou16u.cloudfront.net/shared/howler/webm/amb-water.webm',
            },
            loop: true,
          },
        ],
      },

      {
        name: 'showroom',
        ambient: [
          {
            src: {
              mp3: 'https://dxp24t9jou16u.cloudfront.net/shared/howler/mp3/amb-water.mp3',
              webm: 'https://dxp24t9jou16u.cloudfront.net/shared/howler/webm/amb-water.webm',
            },
            loop: true,
          },
        ],
      },
    ],
  };
};
