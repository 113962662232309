import create from 'utilities/zustand/create';
import { getInitialCustomizationConfiguration } from './config';
import { getRandomBetween, getRandomExcluded } from '../../utilities/math';
import { useUserStore } from 'services/UserService';

export const useAvatarStore = create((set, get) => ({
  customizationConfiguration: null,

  initConfigurationFromAppearance: appearance => {
    const customizationConfiguration = getInitialCustomizationConfiguration();
    if (appearance === null) {
      set({ customizationConfiguration });
      get().randomize();
      customizationConfiguration.forEach(category => {
        category.customized = false;
      });
    } else {
      customizationConfiguration.forEach(category => {
        category.customized = true;
        category.optionPanels.forEach(optionPanels => {
          optionPanels.selections.forEach(selection => {
            const isPrimaryOrSecondary = selection.name === 'jacket' || selection.name === 'shirt';
            if (!isPrimaryOrSecondary) {
              selection.index =
                appearance[category.id]?.[optionPanels.type] === undefined
                  ? 0
                  : appearance[category.id][optionPanels.type]['default'];
            } else {
              if (selection.name === 'jacket') selection.index = appearance[category.id][optionPanels.type]['jacket'];
              if (selection.name === 'shirt') selection.index = appearance[category.id][optionPanels.type]['shirt'];
            }
          });
        });
      });
      set({ customizationConfiguration });
    }
  },

  randomize: () => {
    const { customizationConfiguration } = get();

    customizationConfiguration.forEach(category => {
      const categoryId = category.id;
      category.optionPanels.forEach((optionPanel, i) => {
        //90% we dont want to have facial_details or accesoires
        if (categoryId === 'facial_details' || categoryId === 'accesoires') {
          if (Math.random() < 0.9) {
            get().selectCustomizationOption(categoryId, i, 0);
            return;
          }
        }

        const unused = [];
        if (optionPanel.type === 'style') {
          optionPanel.options.forEach((option, i) => {
            if (option.use === false) unused.push(i);
          });
        }

        const indexStart = 0;
        const indexEnd = optionPanel.options.length - 1;
        const randomOptionIndex =
          unused.length > 0 ? getRandomExcluded(indexStart, indexEnd, unused) : getRandomBetween(indexStart, indexEnd);

        get().selectCustomizationOption(categoryId, i, randomOptionIndex);
        if (categoryId === 'top' && i === 1) {
          get().selectCustomizationOption(categoryId, i, getRandomBetween(indexStart, indexEnd), 1);
        }
      });
    });
  },

  // used to get the user appearance from the current configuration
  getAppearance: () => {
    const appearance = {};
    get().customizationConfiguration.forEach(category => {
      appearance[category.id] = {};
      category.optionPanels.forEach(panel => {
        appearance[category.id][panel.type] = {};
        panel.selections.forEach(selection => {
          appearance[category.id][panel.type][selection.name] = selection.index;
        });
      });
    });
    return appearance;
  },

  getCategoryByIndex: index => {
    return get().customizationConfiguration[index];
  },

  getSelectedCategory: () => {
    return get().customizationConfiguration.find(cat => cat.selected);
  },

  getSelectedCategoryIndex: () => {
    return get().customizationConfiguration.findIndex(cat => cat.selected);
  },

  getCategoryCount: () => {
    return get().customizationConfiguration.length;
  },

  getPreviousCategoryIndex: () => {
    const selectedIndex = get().getSelectedCategoryIndex();
    return selectedIndex !== 0 ? selectedIndex - 1 : false;
  },

  getPreviousCategory: () => {
    const previousIndex = get().getPreviousCategoryIndex();
    if (previousIndex === false) return false;
    return get().customizationConfiguration[previousIndex];
  },

  goToPreviousCategory: () => {
    const previousIndex = get().getPreviousCategoryIndex();
    if (previousIndex === false) return;
    const newCustomizationOptions = get().customizationConfiguration.map((cc, i) => ({
      ...cc,
      selected: i === previousIndex,
    }));
    set({ customizationConfiguration: newCustomizationOptions });
  },

  getNextCategoryIndex: () => {
    const selectedIndex = get().getSelectedCategoryIndex();
    const categoryCount = get().getCategoryCount();
    return selectedIndex < categoryCount - 1 ? selectedIndex + 1 : false;
  },

  getNextCategory: () => {
    const nextIndex = get().getNextCategoryIndex();
    if (nextIndex === false) return false;
    return get().customizationConfiguration[nextIndex];
  },

  goToNextCategory: () => {
    const nextIndex = get().getNextCategoryIndex();
    if (nextIndex === false) return;
    const newCustomizationOptions = get().customizationConfiguration.map((cc, i) => ({
      ...cc,
      selected: i === nextIndex,
    }));
    set({ customizationConfiguration: newCustomizationOptions });
  },

  // used to compute selected colors and stuff from some appearance
  computeAppearanceValues: appearance => {
    const appearanceValues = {};
    get().customizationConfiguration.forEach(category => {
      appearanceValues[category.id] = {};
      category.optionPanels.forEach(panel => {
        appearanceValues[category.id][panel.type] = {};
        panel.selections.forEach(selection => {
          let index = null;
          try {
            index = appearance[category.id][panel.type][selection.name];
          } catch (e) {
            index = 0;
          }
          appearanceValues[category.id][panel.type][selection.name] = panel.options[index];
        });
      });
    });
    return appearanceValues;
  },

  // ui and camera controls
  getSelectedCustomizationCategoryCamera: () => {
    const selectedCategory = get().customizationConfiguration.find(cc => cc.selected);
    if (selectedCategory == null) return null;
    const camera = selectedCategory.camera;
    return camera == null ? null : camera;
  },
  selectCustomizationCategory: (id, selected) => {
    const newCustomizationOptions = get().customizationConfiguration.map(cc => {
      if (id === cc.id) return { ...cc, selected: selected };
      else if (cc.selected) return { ...cc, selected: false };
      else return cc;
    });
    set({ customizationConfiguration: newCustomizationOptions });
  },
  deselectCustomizationCategories: () => {
    const newCustomizationOptions = get().customizationConfiguration.map(cc => {
      return { ...cc, selected: false };
    });
    set({ customizationConfiguration: newCustomizationOptions });
  },
  getCategoryIndexById: categoryId => {
    return get().customizationConfiguration.findIndex(cc => cc.id === categoryId);
  },

  selectNextOrPreviousOption: (categoryId, modifier) => {
    const { customizationConfiguration } = get();
    const categoryIndex = get().getCategoryIndexById(categoryId);
    if (categoryIndex === -1) return;
    //TODO clean up with config remodel
    const panel = customizationConfiguration[categoryIndex].optionPanels[0];
    const { selections, options } = panel;
    const selection = selections[0];
    const { index } = selection;
    let targetIndex = index + modifier;
    if (targetIndex < 0) targetIndex = 0;
    if (targetIndex > options.length - 1) targetIndex = options.length - 1;
    get().selectCustomizationOption(categoryId, 0, targetIndex);
  },

  selectCustomizationOption: (categoryId, panelIndex, optionIndex, selectableIndex = 0) => {
    const { customizationConfiguration } = get();
    const categoryIndex = get().getCategoryIndexById(categoryId);
    if (categoryIndex === -1) return;
    customizationConfiguration[categoryIndex].customized = true;
    const { selections } = customizationConfiguration[categoryIndex].optionPanels[panelIndex];
    selections[selectableIndex] = {
      ...selections[selectableIndex],
      index: optionIndex,
    };
    set({ customizationConfiguration: [...customizationConfiguration] });
    useUserStore.getState().previewAppearance(useAvatarStore.getState().getAppearance());
  },

  getOptionCount: () => {
    const selectedCategory = get().getSelectedCategory();
    if (!selectedCategory) return;
    const styleOptionPanels = selectedCategory.optionPanels.filter(p => p.type === 'style');
    if (styleOptionPanels.length < 1) return;
    return styleOptionPanels[0].options.length;
  },

  getSelectedOptionIndex: () => {
    const selectedCategory = get().getSelectedCategory();
    const styleOptionPanels = selectedCategory?.optionPanels.filter(p => p.type === 'style');
    if (!styleOptionPanels || styleOptionPanels?.length < 1 || !styleOptionPanels[0].selections) return;
    const selection = styleOptionPanels[0].selections[0];
    if (!selection) return;
    return selection.index;
  },
}));

if (module.hot) module.hot.decline();
