import { CategoryTitle, Container } from './styles';
import SmallTeaser from '../TeaserGrid/SmallTeaser';
import TeaserGrid from '../TeaserGrid';
import { useResourceStore } from 'services/ResourceService';
import { CONTENT_TYPE } from 'services/DistrictService/contentTypes';

export default function Resource(props) {
  const { content } = props;
  const { setActiveResource } = useResourceStore.getState();

  const columns = 4;
  return (
    <Container columns={columns}>
      {content.categories &&
        content.categories.map((category, catIndex) => (
          <div key={catIndex}>
            <CategoryTitle>{category.headline}</CategoryTitle>
            <TeaserGrid>
              {category.resources.map((resource, index) => (
                <SmallTeaser
                  content={resource}
                  key={index}
                  index={index}
                  clickHandler={() => {
                    setActiveResource(resource);
                    if (resource.url && resource.type === CONTENT_TYPE.LINK.id) {
                      window.open(resource.url, '_blank');
                    }
                  }}
                  columns={columns}
                />
              ))}
            </TeaserGrid>
          </div>
        ))}
    </Container>
  );
}
