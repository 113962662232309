import { useEventStore } from '../EventService';
import { useSceneStore } from '../SceneService';
import { usePlayerStore } from './index';

export function getSpawnInfo() {
  const { previousRoom } = usePlayerStore.getState();
  const { useAvatars } = useEventStore.getState().event;
  const { zoneGroup } = useSceneStore.getState().scene;

  // eslint-disable-next-line no-console
  // console.log('zoneGroup', zoneGroup);

  const result = { position: [0, 0, 0], rotation: 0 };
  const applyToResult = (spawnZone, useRandomOffset) => {
    const p = spawnZone.position;
    const r = spawnZone.rotation;

    result.position[0] = p.x;
    result.position[1] = p.y;
    result.position[2] = p.z;
    result.rotation = -r.y + Math.PI;

    if (useRandomOffset) {
      result.position[0] += 2 * ((Math.random() - 0.5) * 0.5);
      result.position[2] += 2 * ((Math.random() - 0.5) * 0.5);
    }
  };

  if (!zoneGroup) {
    // eslint-disable-next-line no-console
    console.warn('No zone group', zoneGroup);
    return result;
  }

  const orbitSpawnZone = zoneGroup.children.find(z => z.name.startsWith('orbit_spawn'));
  const mainSpawnZone = zoneGroup.children.find(z => z.name.startsWith('main_spawn'));
  const portalZone = zoneGroup.children.find(z => z.name.startsWith(previousRoom));

  if (!useAvatars && orbitSpawnZone) {
    // eslint-disable-next-line no-console
    // console.log('Found orbit_spawn', orbitSpawnZone);
    applyToResult(orbitSpawnZone, false);
  } else if (previousRoom && portalZone) {
    // eslint-disable-next-line no-console
    // console.log('Found portalZone', portalZone);
    applyToResult(portalZone, true);
  } else if (mainSpawnZone) {
    // eslint-disable-next-line no-console
    // console.log('Found main_spawn', mainSpawnZone);
    applyToResult(mainSpawnZone, true);
  } else {
    // eslint-disable-next-line no-console
    console.warn('No spawn found.', zoneGroup, previousRoom);
  }

  return result;
}
