import styled, { css } from 'styled-components';
import { colors, lgBaseColors } from 'uiLivi/styles';
import { useDeviceStore } from 'services/DeviceService';
import { smallTextStyle } from 'common/text';

export const StyledInput = styled.input`
  display: flex;
  padding: 16px;
  margin: ${props => () => {
    switch (props.margin) {
      case 'none':
        return 0;
      case 'tight':
        return '7px 0';
      default:
        return '15px 0';
    }
  }};
  width: 100%;
  outline: none;
  //border: ${props => (props.border ? `1px solid ${lgBaseColors.grey.medium}` : 'none')};
  border: ${props => (props.border ? `1px solid #4E4E4E` : 'none')};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  background-color: ${colors.background.highlight};
  color: white;
  text-align: ${props => (props.centerText ? 'center' : 'left')};
  flex-grow: 3;
  font-size: 11px;
  font-weight: 600;
  &::placeholder {
    font-weight: 600;
    color: #4e4e4e;
  }
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px ${lgBaseColors.red.base} inset;
  }
  ${props =>
    props.small
      ? css`
          ${smallTextStyle};
          padding: 10px;
        `
      : null}
`;

export function Input(props) {
  const { setKeyboardActive } = useDeviceStore.getState();

  const handleOnFocus = () => {
    setKeyboardActive(true);
  };
  const handleOnBlur = () => {
    setKeyboardActive(false);
  };

  return <StyledInput {...props} value={props.value || ''} onFocus={handleOnFocus} onBlur={handleOnBlur} />;
}
