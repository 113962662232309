import Teaser from 'components/Contents/Screen/Teaser';
import Hover from 'components/Contents/Screen/Hover';
import Pin from 'components/Contents/Pin';
import { useTheaterStore } from 'services/TheaterService';
import { CONTENT_TYPE } from 'services/DistrictService/contentTypes';
import { useContentStore } from 'services/ContentService';
import { useCameraStore } from 'services/CameraService';
import { useMenuStore } from 'services/MenuService';
import { useTalkStore } from 'services/TalkService';
import { useTranslate } from 'services/TranslationService/hooks';
import { useDebugStore } from 'storage/debug';
import { useTheaterContent } from 'utilities/hooks';
import ScreenGlass from 'components/Contents/Screen/ScreenGlass';

export function TheaterScreen(props) {
  const { object, content } = props;
  const { position, rotation } = object;
  const currentTalk = useTheaterStore(state => state.currentTheaterTalk);
  const translate = useTranslate();
  const modifiedContent = { ...content, teaser: { ...content.teaser } };
  const { teaser, teaserType } = useTheaterContent(content);
  modifiedContent.teaser = teaser;
  modifiedContent.teaserType = teaserType;
  modifiedContent.subline = currentTalk
    ? translate('auditorium.stage.subline.join') || 'Join theater session now'
    : translate('auditorium.stage.subline.openschedule') || 'Open schedule';

  const debugStore = useDebugStore();
  const disabled = debugStore.getUiDisabled();

  const openContent = () => {
    const { setFocusContent } = useContentStore.getState();
    setFocusContent({
      object: object,
      content: modifiedContent,
    });
  };

  return (
    <group name={props.name} position={position} rotation={rotation}>
      {modifiedContent.teaser && <Teaser object={object} content={modifiedContent} createSharedTexture />}
      {!disabled && <Hover {...props} onClick={openContent} />}
      {!disabled && content.pin && <Pin {...props} content={modifiedContent} onClick={openContent} />}
    </group>
  );
}

export function RegularScreen(props) {
  const { object, content } = props;
  const { position, rotation } = object;
  const contentTalk = useTalkStore(state => state.talks.find(t => t.location === content.location));
  const modifiedContent = { ...content, talk: contentTalk };
  const debugStore = useDebugStore();
  const disabled = debugStore.getUiDisabled();

  const openContent = () => {
    if (content.location === 'stageLeft01') {
      useMenuStore.getState().setMenuItemActive('timetable', true);
      return;
    }
    if (content.type.id === CONTENT_TYPE.LINK.id && content.url) {
      window.open(content.url, '_blank');
      return;
    }
    const { setFocusContent } = useContentStore.getState();
    setFocusContent({
      object: object,
      content: modifiedContent,
    });
  };

  if (!object?.geometry) return null;

  return (
    <group name={props.name} position={position} rotation={rotation}>
      {content.teaser && <Teaser object={object} content={modifiedContent} />}
      {content.teaser && <ScreenGlass object={object} />}
      {!disabled && <Hover {...props} onClick={openContent} content={modifiedContent} />}
      {!disabled && content.pin && <Pin {...props} onClick={openContent} content={modifiedContent} />}
    </group>
  );
}

export default function Screen(props) {
  const { content } = props;
  const activeContent = useContentStore(state => state.focusContent || state.activeContent);
  const isFree = useCameraStore(state => state.mode === 'free');
  const enabled = isFree && !activeContent;

  const isTheater = content.location === 'stage01';
  return (
    <>
      {isTheater && <TheaterScreen {...props} disabled={!enabled} />}
      {!isTheater && <RegularScreen {...props} disabled={!enabled} />}
    </>
  );
}
