import { useChatStore } from 'services/ChatService';
import { useContentStore } from 'services/ContentService';
import Like from 'uiLivi/assets/Emojis/emoji_like.png';
import Love from 'uiLivi/assets/Emojis/emoji_love.png';
import Clap from 'uiLivi/assets/Emojis/emoji_clap.png';
import Smile from 'uiLivi/assets/Emojis/emoji_smile.png';
import Celebration from 'uiLivi/assets/Emojis/emoji_celebration.png';
import Greet from 'uiLivi/assets/Emojis/emoji_greet.png';
import styled from 'styled-components';
import { usePlayerStore } from 'services/PlayerService';
import { useUserStore } from 'services/UserService';
import { colors } from 'uiLivi/styles';

const EmoteRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  pointer-events: ${props => (props.show ? 'all' : 'none')};
  background-color: ${props => (props.isFullHeight ? 'black' : colors.background.base)};
`;

const EmoteButton = styled.div`
  width: 18px;
  height: 18px;
  margin: 10px 0;
  position: relative;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  user-select: none;

  background-image: url(${props => props.src});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  &:hover {
    transform: scale(1.4);
    filter: brightness(110%);
  }
`;

const emojis = [
  { img: Greet, type: 'greet' },
  { img: Celebration, type: 'celebration' },
  { img: Smile, type: 'smile' },
  { img: Clap, type: 'clap' },
  { img: Love, type: 'love' },
  { img: Like, type: 'like' },
];

const getReactionIndexType = type => {
  switch (type) {
    case 'like':
      return 3;
    case 'love':
      return 2;
    case 'clap':
      return 4;
    case 'smile':
      return 5;
    case 'celebration':
      return 6;
    case 'greet':
      return 1;
  }
};

const ChatEmotes = ({ show }) => {
  const sendEmoji = useChatStore(state => state.sendEmoji);
  const playerActions = usePlayerStore.getState().playerActions;
  const user = useUserStore(state => state.user);
  const content = useContentStore(state => state.activeContent);
  const fullscreenContentInFocus = content && content.type.isFullscreen;

  const onClickEmoji = emoji => {
    sendEmoji(emoji.type, user.id);
    if (!fullscreenContentInFocus) playerActions.setReaction(getReactionIndexType(emoji.type));
  };

  return (
    <EmoteRow show={show} isFullHeight={content}>
      {emojis.map((emoji, index) => (
        <EmoteButton
          key={index}
          src={emoji.img}
          onClick={() => {
            onClickEmoji(emoji);
          }}
        />
      ))}
    </EmoteRow>
  );
};

export default ChatEmotes;
