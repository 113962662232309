import React, { useRef } from 'react';
import { AdditiveBlending, RepeatWrapping, TextureLoader } from 'three';
import { useFrame, useLoader } from '@react-three/fiber';
import { useSpring } from 'react-spring';
import { useWindowStore } from 'services/WindowService';
import hoverFrag from './shader/hover.frag';
import hoverVert from './shader/hover.vert';
import reflectionPng from './Reflection.png';
import { useHowlerStore } from 'services/HowlerService';

export default function Hover({ object, onClick, disabled }) {
  const playClickUiSound = useHowlerStore(state => state.playClickUiSound);
  const fadeOutSound = useHowlerStore(state => state.fadeOutSound);
  const { geometry: screen } = object;
  if (!screen) {
    return null;
  }
  const [reflection] = useLoader(TextureLoader, [reflectionPng]);
  reflection.wrapS = RepeatWrapping;

  const materialRef = useRef();
  const meshRef = useRef();

  const [{ hover }, setHover] = useSpring(() => ({
    hover: 0.0,
    config: { tension: 200, friction: 30 },
  }));

  const [{ active }, setActive] = useSpring(() => ({
    active: 0.0,
  }));

  const args = {
    uniforms: {
      offset: { value: 0 },
      reflTex: { type: 't', value: reflection },
    },
  };

  useFrame(() => {
    setActive({ active: !disabled ? 1 : 0 });
    materialRef.current.uniforms.offset.value = useWindowStore.getState().supportsTouch
      ? active.value
      : active.value * hover.value;
  });

  const setWindowStoreHover = useWindowStore(state => state.setHover);

  return (
    <>
      <mesh
        name={'ScreenOver'}
        ref={meshRef}
        position={[0, 0, 0.02]}
        geometry={screen}
        onPointerOver={() => {
          if (active.value > 0) setWindowStoreHover(true);
          setHover({ hover: 1 });
        }}
        onPointerOut={() => {
          setWindowStoreHover(false);
          setHover({ hover: 0 });
        }}
        onClick={e => {
          e.stopPropagation();
          if (active.value === 1) {
            playClickUiSound();
            fadeOutSound();
            onClick();
          }
        }}
      >
        <shaderMaterial
          ref={materialRef}
          args={[args]}
          vertexShader={hoverVert}
          fragmentShader={hoverFrag}
          blending={AdditiveBlending}
          transparent={true}
          visible={true}
          needsUpdate={true}
          attach="material"
        />
      </mesh>
    </>
  );
}
