import { useFrame, useLoader } from '@react-three/fiber';
import { Suspense, useEffect, useRef, useState } from 'react';
import { useContentStore } from 'services/ContentService';
import { CONTENT_TYPE } from 'services/DistrictService/contentTypes';
import { useLightmaps } from 'services/MaterialService/hooks';
import { useWindowStore } from 'services/WindowService';
import {
  AdditiveBlending,
  AnimationMixer,
  Box3,
  BoxGeometry,
  LinearFilter,
  LinearMipMapLinearFilter,
  MeshBasicMaterial,
  sRGBEncoding,
  Vector3,
} from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { useEventStore } from 'services/EventService';
import fromCdn from 'utilities/cdn';
import { useDebugStore } from 'storage/debug';
import { clone } from 'three/examples/jsm/utils/SkeletonUtils';
import { useAssetDetails } from 'services/AssetDetailService';
import { useDebugAssetVersion } from '../../../services/DebugAssetVersion';
import { useEnvironmentStore } from '../Environment/store';

const configureLightMap = lightMap => {
  lightMap.flipY = false;
  lightMap.generateMipmaps = true;
  lightMap.anisotropy = 16;
  lightMap.minFilter = LinearMipMapLinearFilter;
  lightMap.magFilter = LinearFilter;
  lightMap.encoding = sRGBEncoding;
};

const Product = ({ product }) => {
  const environmentConfiguration = useEnvironmentStore(state => state.environmentConfiguration);
  const setWindowStoreHover = useWindowStore(state => state.setHover);
  const debugAssetVersion = useDebugAssetVersion(state => state.version);
  const isDetailMobile = useAssetDetails(state => state.isDetailMobile);
  const versionParam = `?v=${debugAssetVersion}`;
  const gltf = useLoader(
    GLTFLoader,
    fromCdn(
      product.meshMobile
        ? isDetailMobile
          ? product.meshMobile + versionParam
          : product.mesh + versionParam
        : product.mesh + versionParam
    )
  );
  const lightmaps = useLightmaps();
  const [sceneData, setSceneData] = useState(null);

  //mount
  useEffect(() => {
    const renderOrder = product.renderOrder ? Number(product.renderOrder) : 0;
    const objectScene = clone(gltf.scene);
    objectScene.traverse(o => {
      if (o.isMesh) {
        o.renderOrder = renderOrder;
      }
      if (o.material) {
        const lightmap = lightmaps.find(lm => o.name.includes(lm.tag));
        if (lightmap) {
          configureLightMap(lightmap.texture);
          o.material.lightMap = lightmap.texture;
        }
        o.material.depthWrite = true;
        o.castShadow = true;
        o.receiveShadow = true;

        if (o.material.name === 'cloud') {
          o.material.depthWrite = false;
          o.castShadow = false;
          o.receiveShadow = false;
        }

        if (o.material.name.includes('_cutout')) {
          o.material.transparent = false;
          o.material.alphaTest = 0.5;
        }

        if (o.material.name.includes('_add')) {
          o.material.blending = AdditiveBlending;
          o.material.transparent = true;
          o.material.depthWrite = false;
          o.receiveShadow = false;
          o.castShadow = false;
        }
      }
    });

    const bb = new Box3().setFromObject(objectScene);
    const boxGeom = new BoxGeometry(bb.max.x - bb.min.x, bb.max.y - bb.min.y, bb.max.z - bb.min.z);
    const boxMat = new MeshBasicMaterial({ color: 0xffffff, visible: false });
    const boxPos = new Vector3(0, bb.max.y * 0.5, 0);

    const { animations } = gltf;
    const animationMixer = new AnimationMixer();
    if (animations.length > 0) {
      animations.forEach(animation => {
        const action = animationMixer.clipAction(animation, objectScene);
        action.setLoop(true);
        action.play();
      });
    }

    const content = {
      type: CONTENT_TYPE.PRODUCT,
      headline: product.headline || 'Product',
      pin: {
        normal: [0.6, 0, -0.76],
        offset: [0, 0, 0],
        scale: 0.3,
      },
      product: {
        ...product,
        object: clone(objectScene),
      },
    };

    setSceneData({
      animationMixer,
      objectScene,
      boxGeom,
      boxMat,
      boxPos,
      content,
    });
  }, [gltf]);

  useEffect(() => {
    if (!sceneData) return;
    const { objectScene } = sceneData;
    objectScene.traverse(o => {
      if (o.material) {
        if (o.material.blending !== AdditiveBlending) {
          o.material.envMapIntensity = environmentConfiguration.envMap.intensity;
        } else {
          o.material.envMapIntensity = 1;
        }
      }
    });
  }, [sceneData, environmentConfiguration]);

  // update animation mixer
  useFrame((c, delta) => {
    const animationMixer = sceneData?.animationMixer;
    if (animationMixer) {
      animationMixer.update(delta);
    }
  });

  const splitByComma = string => {
    if (!string) {
      return [0, 0, 0];
    }
    const splits = string.split(',');
    if (splits.length === 1) {
      splits[1] = splits[0];
      splits[2] = splits[0];
    }
    return splits.map(x => Number(x));
  };

  const splitByCommaAndConvertDeg2Rag = string => {
    return splitByComma(string).map(x => (Number(x) * Math.PI) / 180);
  };

  const autoRotation = splitByCommaAndConvertDeg2Rag(product.autoRotation);
  const productRef = useRef(null);

  useFrame((context, deltaTime) => {
    if (!productRef.current || autoRotation == null) return;
    productRef.current.rotation.x += autoRotation[0] * deltaTime;
    productRef.current.rotation.y += autoRotation[1] * deltaTime;
    productRef.current.rotation.z += autoRotation[2] * deltaTime;
  });

  if (!sceneData) return null;

  const { objectScene, boxPos, boxGeom, boxMat, content } = sceneData;

  const enableClickMesh = content.product.productContents.length > 0;

  return (
    <group
      name={product.headline}
      position={splitByComma(product.location)}
      rotation={splitByCommaAndConvertDeg2Rag(product.rotation)}
      scale={splitByComma(product.scale)}
      ref={productRef}
    >
      <primitive object={objectScene} />
      {enableClickMesh && (
        <mesh
          position={boxPos}
          geometry={boxGeom}
          material={boxMat}
          onPointerOver={e => {
            e.stopPropagation();
            setWindowStoreHover(true);
          }}
          onPointerOut={() => {
            setWindowStoreHover(false);
          }}
          onClick={e => {
            e.stopPropagation();
            useContentStore.getState().setActiveContent(content);
          }}
        />
      )}
    </group>
  );
};

export default function Products({ district, visible }) {
  const isDetailMobile = useAssetDetails(state => state.isDetailMobile);
  const debugAssetVersion = useDebugAssetVersion(state => state.version);

  const products = useEventStore(state =>
    state.event.products.filter(p => {
      // filter products with meshMobile === none
      if (isDetailMobile && p.meshMobile && p.meshMobile.toLowerCase() === 'none') {
        return false;
      }
      return p.district === district.room;
    })
  );
  const debugStore = useDebugStore();

  if (debugStore.getMinimal3d()) return null;
  if (!products) return null;
  return (
    <group name={'Products'} visible={visible}>
      {products.map((product, i) => {
        if (debugAssetVersion === 0) {
          return <Product key={i} product={product} />;
        } else {
          return (
            <Suspense fallback={null}>
              <Product key={i} product={product} />
            </Suspense>
          );
        }
      })}
    </group>
  );
}
