import { CONTENT_TYPE } from 'services/DistrictService/contentTypes';

import IFrame from './IFrame';
import LiveStream from './LiveStream';
import Video from './Video';
import Resource from './Resource';
import FullScreenImage from './FullScreenImage';
import ObjectViewer from './ObjectViewer';
import fromCdn from 'utilities/cdn';

export default function Content(props) {
  const { content } = props;
  switch (content.type.id) {
    case CONTENT_TYPE.LIVESTREAM.id:
      return <LiveStream content={content} />;
    case CONTENT_TYPE.VIDEO.id:
      return <Video {...props} url={fromCdn(content.url)} />;
    case CONTENT_TYPE.SINGLE_VIDEO.id:
      return <Video {...props} url={fromCdn(content.url)} />;
    case CONTENT_TYPE.RESOURCE.id:
      return <Resource {...props} />;
    case CONTENT_TYPE.IMAGE.id:
      return <FullScreenImage imgSrc={fromCdn(content.url)} />;
    case CONTENT_TYPE.PRODUCT.id:
      return <ObjectViewer {...props} />;
    case CONTENT_TYPE.PDF.id:
    case CONTENT_TYPE.IFRAME.id:
    default:
      return (
        <IFrame
          {...props}
          attributes={{
            src: content.url,
            allow:
              'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; camera; microphone; fullscreen',
            autoPlay: true,
          }}
        />
      );
  }
}
