import { useEffect } from 'react';
import { useHowlerStore } from 'services/HowlerService';

const AmbientSounds = () => {
  const isHowlerEnabled = useHowlerStore(state => state.isHowlerEnabled);

  useEffect(() => {
    useHowlerStore.getState().updateAmbient();
  }, [isHowlerEnabled]);

  return null;
};

export default AmbientSounds;
