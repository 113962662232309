import create from 'utilities/zustand/create';
import Storage from 'utilities/storage';

const SOCKET_DEBUG_ENABLED = 'SOCKET_DEBUG_ENABLED';
const TRACKING_DEBUG_ENABLED = 'TRACKING_DEBUG_ENABLED';
const DEVELOPER_CONTENT_ENABLED = 'DEVELOPER_CONTENT_ENABLED_2';
const SOCKET_TARGET = 'SOCKET_TARGET';
const SELECTED_DAT_GUI_INDEX = 'SELECTED_DAT_GUI_INDEX';
const MATERIALS_INDEX = 'MATERIALS_INDEX_2';
const MINIMAL_3D = 'MINIMAL_3D';
const RENDER_TRANSLATION_KEYS_ENABLED = 'RENDER_TRANSLATION_KEYS_ENABLED';
const STATS_ENABLED = 'STATS_ENABLED';
const DISABLED_UI = 'DISABLED_UI';
const FLYCAM_ENABLED = 'FLYCAM_ENABLED';
const AMBIENT_MUSIC_ENABLED = 'AMBIENT_MUSIC_ENABLED';
const DEBUG_ENVIRONMENT_ENABLED = 'DEBUG_ENVIRONMENT_ENABLED';
const DEBUG_SHOW_MANY_AVATARS = 'DEBUG_SHOW_MANY_AVATARS';
const UI = 'UI';

const storage = new Storage();
storage.init('live_debug_1');
storage.defineEntry(DEVELOPER_CONTENT_ENABLED, false);
storage.defineEntry(TRACKING_DEBUG_ENABLED, false);
storage.defineEntry(SOCKET_DEBUG_ENABLED, false);
storage.defineEntry(SOCKET_TARGET, 'AUTO');
storage.defineEntry(SELECTED_DAT_GUI_INDEX, 0);
storage.defineEntry(MATERIALS_INDEX, -1);
storage.defineEntry(MINIMAL_3D, false);
storage.defineEntry(RENDER_TRANSLATION_KEYS_ENABLED, false);
storage.defineEntry(STATS_ENABLED, false);
storage.defineEntry(DISABLED_UI, false);
storage.defineEntry(FLYCAM_ENABLED, false);
storage.defineEntry(AMBIENT_MUSIC_ENABLED, true);
storage.defineEntry(DEBUG_ENVIRONMENT_ENABLED, false);
storage.defineEntry(DEBUG_SHOW_MANY_AVATARS, false);
storage.defineEntry(UI, 'livi');
storage.onSetItem = () => {
  useDebugStore.setState({});
};

const storageApi = {
  setDeveloperContentEnabled: enabled => {
    storage.setItem(DEVELOPER_CONTENT_ENABLED, enabled);
  },
  getDeveloperContentEnabled: () => {
    return storage.getItem(DEVELOPER_CONTENT_ENABLED);
  },
  setSocketDebugEnabled: enabled => {
    storage.setItem(SOCKET_DEBUG_ENABLED, enabled);
  },
  getSocketDebugEnabled: () => {
    return storage.getItem(SOCKET_DEBUG_ENABLED);
  },
  setTrackingDebugEnabled: enabled => {
    storage.setItem(TRACKING_DEBUG_ENABLED, enabled);
  },
  getTrackingDebugEnabled: () => {
    return storage.getItem(TRACKING_DEBUG_ENABLED);
  },
  setMinimal3d: enabled => {
    storage.setItem(MINIMAL_3D, enabled);
  },
  getMinimal3d: () => {
    return storage.getItem(MINIMAL_3D);
  },
  setAmbientMusicEnabled: enabled => {
    storage.setItem(AMBIENT_MUSIC_ENABLED, enabled);
  },
  getAmbientMusicEnabled: () => {
    return storage.getItem(AMBIENT_MUSIC_ENABLED);
  },
  setDebugEnvironmentEnabled: enabled => {
    storage.setItem(DEBUG_ENVIRONMENT_ENABLED, enabled);
  },
  getDebugEnvironmentEnabled: () => {
    return storage.getItem(DEBUG_ENVIRONMENT_ENABLED);
  },
  setUiDisabled: enabled => {
    storage.setItem(DISABLED_UI, enabled);
  },
  getUiDisabled: () => {
    return storage.getItem(DISABLED_UI);
  },
  setFlyCamEnabled: enabled => {
    storage.setItem(FLYCAM_ENABLED, enabled);
  },
  getFlyCamEnabled: () => {
    return storage.getItem(FLYCAM_ENABLED);
  },
  setSocketTarget: target => {
    storage.setItem(SOCKET_TARGET, target);
  },
  getSocketTarget: () => {
    return storage.getItem(SOCKET_TARGET);
  },
  setSelectedDatGuiIndex: selectedIndex => {
    storage.setItem(SELECTED_DAT_GUI_INDEX, selectedIndex);
  },
  getSelectedDatGuiIndex: () => {
    return storage.getItem(SELECTED_DAT_GUI_INDEX);
  },
  setMaterialsIndex: selectedIndex => {
    storage.setItem(MATERIALS_INDEX, selectedIndex);
  },
  getMaterialsIndex: () => {
    return storage.getItem(MATERIALS_INDEX);
  },
  setRenderTranslationKeysEnabled: enabled => {
    storage.setItem(RENDER_TRANSLATION_KEYS_ENABLED, enabled);
  },
  getRenderTranslationKeysEnabled: () => {
    return storage.getItem(RENDER_TRANSLATION_KEYS_ENABLED);
  },
  setStatsEnabled: enabled => {
    storage.setItem(STATS_ENABLED, enabled);
  },
  getStatsEnabled: () => {
    return storage.getItem(STATS_ENABLED);
  },
  setShowManyAvatars: enabled => {
    storage.setItem(DEBUG_SHOW_MANY_AVATARS, enabled);
  },
  getShowManyAvatars: () => {
    return storage.getItem(DEBUG_SHOW_MANY_AVATARS);
  },
  setUi: ui => {
    storage.setItem(UI, ui);
  },
  getUi: () => {
    switch (storage.getItem(UI)) {
      case 'off':
        return { uiVe: false, uiLivi: false, uiOff: true };
      case 'livi':
        return { uiVe: false, uiLivi: true, uiOff: false };
      case 've':
        return { uiVe: true, uiLivi: false, uiOff: false };
    }
    return storage.getItem(UI);
  },
};
export const useDebugStore = create(() => storageApi);
export default storageApi;
