import React, { useEffect, useMemo, useState } from 'react';
import { useContentStore } from 'services/ContentService';
import Screen from './Screen';

export default function Contents({ contentGroup, visible }) {
  const contents = useContentStore(state => state.contents);

  const [contentComponents, setContentComponents] = useState(null);

  useEffect(() => {
    if (!contents || contents.length === 0) return;
    const result = [];
    contentGroup.children.forEach(o => {
      const [location] = o.name.split('_');
      const content = contents.find(a => a.location === location);
      if (!content) return;
      if (!content.type) {
        // eslint-disable-next-line no-console
        console.warn('No Type for content', content);
        return;
      }
      result.push(<Screen name={content.location} key={content.location} object={o} content={content} />);
    });
    setContentComponents(result);
  }, [contentGroup, contents]);

  if (!contentComponents) return null;
  return <group name={'Contents'}>{visible && contentComponents}</group>;
}
